define("ember-cli-stripe/components/stripe-checkout", ["exports", "ember-cli-stripe/templates/components/stripe-checkout"], function (_exports, _stripeCheckout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Stripe checkout component for accepting payments with
   * an embedded form.
   *
   * Stripe docs: https://stripe.com/docs/tutorials/checkout
   * List of possible Stripe options: https://stripe.com/docs/checkout#integration-simple-options
   *
   * Usage:
   * {{stripe-checkout
   *   description=billingPlan.description
   *   amount=billingPlan.amount
   * }}
   *
   */
  var _default = Ember.Component.extend({
    classNames: ['stripe-checkout'],
    attributeBindings: ['isDisabled:disabled'],
    tagName: 'button',
    layout: _stripeCheckout.default,
    stripe: Ember.inject.service(),

    /**
     * Stripe checkout button text.
     */
    label: 'Pay with card',

    /**
     * When true, the Stripe checkout button is disabled.
     */
    isDisabled: false,

    /**
     * Controls opening the Stripe Checkout modal dynamically.
     * Will open the mcheckout modal when true.
     */
    showCheckout: false,
    hasBlock: Ember.computed.bool('template').readOnly(),

    /**
     * Kick up the modal if we're clicked.
     */
    click: function click(e) {
      e.preventDefault();
      this.openCheckout();
    },

    /**
     * Opens the Stripe modal for payment.
     */
    openCheckout: function openCheckout() {
      this.get('stripe').open(this);
    },
    closeCheckout: function closeCheckout() {
      this.get('stripe').close(this);
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.get('stripe').registerComponent(this);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('showCheckout')) {
        this.openCheckout();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.closeCheckout();
      this.get('stripe').unregisterComponent(this);
    }
  });

  _exports.default = _default;
});