define("ember-notify/templates/components/ember-notify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4z11EPJi",
    "block": "{\"symbols\":[\"message\",\"message\",\"close\",\"&default\"],\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"messages\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[27,[32,4]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"ember-notify/message\",[[24,0,\"ember-notify clearfix\"]],[[\"@message\",\"@theme\",\"@closeAfter\"],[[32,1],[32,0,[\"theme\"]],[32,0,[\"closeAfter\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[18,4,[[32,2],[32,3]]],[2,\"\\n    \"]],\"parameters\":[2,3]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"ember-notify/message\",[[24,0,\"ember-notify clearfix\"]],[[\"@message\",\"@theme\",\"@closeAfter\"],[[32,1],[32,0,[\"theme\"]],[32,0,[\"closeAfter\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-notify/templates/components/ember-notify.hbs"
    }
  });

  _exports.default = _default;
});