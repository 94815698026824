define("ember-l10n/templates/get-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "anecG7gT",
    "block": "{\"symbols\":[\"part\",\"&default\"],\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,1,[\"name\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[18,2,[[32,1,[\"text\"]],[32,1,[\"name\"]]]]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[2,[32,1,[\"text\"]]]],\"parameters\":[]},{\"statements\":[[1,[32,1,[\"text\"]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"unescapeText\",\"if\",\"messageParts\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-l10n/templates/get-text.hbs"
    }
  });

  _exports.default = _default;
});