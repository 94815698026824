define("semantic-ui-ember/templates/components/ui-radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QDd1PQPH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"input\"],[15,3,[34,0]],[15,\"tabindex\",[34,1]],[15,\"checked\",[30,[36,3],[[35,2]],null]],[15,\"disabled\",[30,[36,3],[[35,4]],null]],[15,4,[34,5]],[12],[13],[2,\"\\n\"],[10,\"label\"],[12],[1,[34,6]],[13],[2,\"\\n\"],[18,1,[[30,[36,7],[[32,0],\"execute\"],null]]]],\"hasEval\":false,\"upvars\":[\"name\",\"tabindex\",\"checked\",\"unbound\",\"disabled\",\"type\",\"label\",\"action\"]}",
    "meta": {
      "moduleName": "semantic-ui-ember/templates/components/ui-radio.hbs"
    }
  });

  _exports.default = _default;
});