define("ember-app-scheduler/scheduler", ["exports", "@ember/test-waiters"], function (_exports, _testWaiters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.beginTransition = beginTransition;
  _exports.endTransition = endTransition;
  _exports.setupRouter = setupRouter;
  _exports.reset = reset;
  _exports.didTransition = didTransition;
  _exports.whenRoutePainted = whenRoutePainted;
  _exports.whenRouteIdle = whenRouteIdle;
  _exports.routeSettled = routeSettled;
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var APP_SCHEDULER_LABEL = 'ember-app-scheduler';
  var APP_SCHEDULER_HAS_SETUP = '__APP_SCHEDULER_HAS_SETUP__';

  var _whenRouteDidChange;

  var _whenRouteIdle;

  var IS_FASTBOOT = typeof window.FastBoot !== 'undefined';
  var waiter = (0, _testWaiters.buildWaiter)('ember-app-scheduler-waiter');
  reset();
  var Scheduler = (_dec = Ember._tracked, (_class = function Scheduler() {
    _classCallCheck(this, Scheduler);

    _initializerDefineProperty(this, "isIdle", _descriptor, this);
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isIdle", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  })), _class));
  var scheduler = new Scheduler();
  var _default = scheduler;
  /**
   * Initializes the top level promise that initiates the post-render work.
   */

  _exports.default = _default;

  function beginTransition() {
    if (_whenRouteDidChange.isResolved) {
      _whenRouteDidChange = _defer(APP_SCHEDULER_LABEL);
      _whenRouteIdle = _whenRouteDidChange.promise.then(function () {
        var scheduledWorkToken = waiter.beginAsync();
        return new Ember.RSVP.Promise(function (resolve) {
          Ember.run.schedule('afterRender', null, function () {
            requestAnimationFrame(function () {
              requestAnimationFrame(resolve);
            });
          });
        }).finally(function () {
          waiter.endAsync(scheduledWorkToken);
          mark('appSchedulerEnd');
          measure('appScheduler', 'appSchedulerStart', 'appSchedulerEnd');
        });
      });
      scheduler.isIdle = false;
    }
  }
  /**
   * Initiates the post-render work.
   */


  function endTransition() {
    _whenRouteDidChange.resolve();

    scheduler.isIdle = true;
    mark('appSchedulerStart');
  }
  /**
   * Correct connects the router's transition events to
   * app scheduler's work.
   *
   * @param {RouterService|Router} router An instance of a RouterService or an Ember Router.
   */


  function setupRouter(router) {
    if (IS_FASTBOOT || router[APP_SCHEDULER_HAS_SETUP]) {
      return;
    }

    (false && !(router instanceof Ember.Router) && Ember.deprecate('The use of Ember.Router in setupRouter is deprecated. Please use the router service.', router instanceof Ember.Router, {
      id: 'ember-app-scheduler.setupRouter',
      until: '6.0.0'
    }));
    router[APP_SCHEDULER_HAS_SETUP] = true;

    if (true || router instanceof Ember.Service) {
      // @ts-ignore
      Ember.addListener(router, 'routeWillChange', beginTransition); // @ts-ignore

      Ember.addListener(router, 'routeDidChange', endTransition);
    } else {
      Ember.addListener(router, 'willTransition', beginTransition);
      Ember.addListener(router, 'didTransition', endTransition);
    }

    Ember._registerDestructor(router, reset);
  }
  /**
   * Resets the state of app scheduler's top-level scheduled work promise.
   */


  function reset() {
    _whenRouteDidChange = _defer(APP_SCHEDULER_LABEL);
    _whenRouteIdle = _whenRouteDidChange.promise.then();
    waiter.reset();

    if (!IS_FASTBOOT) {
      _whenRouteDidChange.resolve();
    }
  }
  /**
   * Top level promise that represents the entry point for deferred work.
   * Subsequent promises are chained off this promise, successively composing
   * them together to approximate when painting has occurred.
   *
   * @public
   * @return {Promise} The top-level scheduled work promise.
   */


  function didTransition() {
    (false && !(false) && Ember.deprecate('The `didTransition` function is deprecated. Please use `whenRouteIdle` instead.', false, {
      id: 'ember-app-scheduler.didTransition',
      until: '4.0.0'
    }));
    return _whenRouteDidChange.promise;
  }
  /**
   * This promise, when resolved, approximates after the route is first painted.
   * This can be used to schedule work to occur that is lower priority than initial
   * work (content outside of the viewport, rendering non-critical content).
   *
   * @public
   * @return {Promise} The scheduled work promise.
   */


  function whenRoutePainted() {
    (false && !(false) && Ember.deprecate('The `whenRoutePainted` function is deprecated. Please use `whenRouteIdle` instead.', false, {
      id: 'ember-app-scheduler.whenRoutePainted',
      until: '4.0.0'
    }));
    return _whenRouteIdle;
  }
  /**
   * This promise, when resolved, approximates after content is painted.
   *
   * @public
   * @return {Promise} The scheduled work promise.
   */


  function whenRouteIdle() {
    return _whenRouteIdle;
  }
  /**
   * Allows for tests to pause until the scheduled work
   * promise is completed.
   *
   * @return {Promise} The scheduled work promise.
   */


  function routeSettled() {
    return _whenRouteIdle;
  }

  function _defer(label) {
    var _isResolved = false;

    var _resolve;

    var _reject;

    var promise = new Ember.RSVP.Promise(function (resolve, reject) {
      _resolve = function _resolve() {
        _isResolved = true;
        resolve();
      };

      _reject = reject;
    }, label);
    return {
      promise: promise,
      resolve: _resolve,
      reject: _reject,

      get isResolved() {
        return _isResolved;
      }

    };
  }

  function mark(markName) {
    try {
      performance.mark(markName);
    } catch (ex) {
      console.warn("performance.mark could not be executed because of ".concat(ex.message));
    }
  }

  function measure(measureName, startMark, endMark) {
    try {
      performance.measure(measureName, startMark, endMark);
    } catch (ex) {
      console.warn("performance.measure could not be executed because of ".concat(ex.message));
    }
  }
});