define("ember-l10n/utils/fetch-json-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fetchJsonFile = fetchJsonFile;

  function fetchJsonFile(fileName) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      var request = new XMLHttpRequest();
      request.open('GET', fileName);
      request.addEventListener('load', function () {
        try {
          var responseText = this.responseText;
          var json = JSON.parse(responseText);
          resolve(json);
        } catch (error) {
          reject(error);
        }
      });
      request.addEventListener('error', reject);
      request.send();
    });
  }
});